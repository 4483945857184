import './Pages.css';
import React, {useState, useEffect} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Grid,
    Box,
    Typography,
    Fade,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent
} from '@mui/material';
import Progress from '../Components/Progress';

const Inicio = () => {

    const [checked,
        setChecked] = useState(false);

    useEffect(() => {
        setChecked(true);
    }, []);

    const [expanded,
        setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded
            ? panel
            : false);
    };

    return (
        <Grid container alignItems="stretch">
            <Fade
                style={{
                transitionDelay: checked
                    ? '500ms'
                    : '0ms'
            }}
                in={checked}
                {...(checked ? { timeout: 500 } : {})}>
                <Grid item xs={12} md={10} lg={6}>
                    <Box
                        display="flex"
                        minHeight="100vh"
                        flexDirection="column"
                        sx={{
                        px: 2
                    }}>

                        <Typography
                            fontWeight="bold"
                            className="titleVariant2"
                            sx={{
                            mb: 1,
                            ml: 1,
                            '@media (min-width: 1200px)': {
                                mt: 10
                            }
                        }}>
                            Qué es
                            <br/>
                            Track The Trucker?
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <Card
                                    sx={{
                                    mx: 1,
                                    my: 1
                                }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={5}
                                                sm={3}
                                                md={12}
                                                sx={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                <Box
                                                    sx={{
                                                    width: 160,
                                                    m: -1
                                                }}>
                                                    <Progress/>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={7}
                                                sm={9}
                                                md={12}
                                                sx={{
                                                m: 'auto'
                                            }}>
                                                <Typography variant="body2">
                                                    Un 59% de los kilómetros recorridos por vehículos de carga en Chile son
                                                    <b> anónimos</b>, es decir, no se sabe quién está al volante.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                    mx: 1,
                                    my: 1
                                }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={5} sm={3} md={12}>
                                                <Typography
                                                    className="animatedVariant"
                                                    sx={{
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    fontSize: '3.9rem'
                                                }}>90%</Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={7}
                                                sm={9}
                                                md={12}
                                                sx={{
                                                m: 'auto'
                                            }}>
                                                <Typography variant="body2">
                                                    Track The Trucker ofrece una asignación con al menos un 90% de precisión.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8}>
                                <Box
                                    sx={{
                                    mx: 1,
                                    my: 1
                                }}>
                                    <Accordion
                                        expanded={expanded === 'panel1'}
                                        onChange={handleChange('panel1')}
                                        sx={{
                                        mb: 1
                                    }}>
                                        <AccordionSummary
                                            expandIcon={< ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header">
                                            <Typography>
                                                ⚠️ Problema</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2">
                                                Una asignación errónea puede
                                                <b> comprometer el cumplimiento de normativas y el trabajo del supervisor</b>. Las razones por las que es complicado asignar un chofer adecuadamente son: <br/>
                                                <br/>
                                                ⛔ Muchos conductores evitan la identificación por preocupaciones de privacidad o
                                                legales.
                                                <br/>
                                                <br/>
                                                ⛔ Cámaras, micrófonos y aplicaciones móviles pueden amenazar la privacidad.
                                                <br/>
                                                <br/>
                                                ⛔ No se puede vincular contractualmente a un conductor con un vehículo debido a
                                                los sistemas de turno.
                                                <br/>
                                                <br/>
                                                ⛔ Los puntos de control con guardias no son viables en todas las rutas y la
                                                verificación con identificación tiene limitaciones legales.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion >
                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                        <AccordionSummary
                                            expandIcon={< ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header">
                                            <Typography>💡Solución</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2">
                                                Track The Trucker ha desarrollado una solución innovadora para el desafío de
                                                identificar conductores en grandes flotas de vehículos. A través de un avanzado
                                                <b> modelo matemático que combina redes neuronales probabilísticas y aprendizaje
                                                automático profundo </b> se asigna un conductor a un viaje dependiendo de su
                                                comportamiento.
                                                <br/>
                                                <br/>
                                                ✅ No compromete la privacidad de los conductores.
                                                <br/>
                                                <br/>
                                                ✅ No requiere hardware adicional.
                                                <br/>
                                                <br/>
                                                ✅ No depende de la instalación de aplicaciones móviles.
                                                <br/>
                                                <br/>
                                                ✅ No depende de intervención humana.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Fade>
            <Fade in={checked} {...(checked ? { timeout: 600 } : {})}>
                <Grid item lg={6}>
                    <Box
                        className='boxImage2'
                        display="flex"
                        minHeight="100vh"
                        height="100%"
                        flexDirection="column"></Box>
                </Grid>
            </Fade>
        </Grid>
    );
};

export default Inicio;
