import React from 'react';
import {
    Grid,
    Typography,
    Box,
    Link,
    Divider,
    Chip
} from '@mui/material';

function Footer() {
    return (
        <Box sx={{
            p: 3,
            backgroundColor: '#c6c9d1'
        }}>
            <Grid container>
                <Grid item xs={4} md={2}>
                    <Typography variant="h6" fontWeight="bold" color="textSecondary">
                        Menu
                    </Typography>
                    <Box sx={{
                        my: 1
                    }}>
                        <Link href="/" variant="body2" color="textSecondary" underline="hover">
                            Inicio
                            <br/>
                        </Link>
                    </Box>
                    <Box sx={{
                        my: 1
                    }}>
                        <Link
                            href="/#/que-es-track-the-trucker"
                            variant="body2"
                            color="textSecondary"
                            underline="hover">
                            Qué es Track The Trucker
                            <br/>
                        </Link>
                    </Box>
                    <Box sx={{
                        my: 1
                    }}>
                        <Link
                            href="/#/quienes-somos"
                            variant="body2"
                            color="textSecondary"
                            underline="hover">
                            Quiénes Somos
                            <br/>
                        </Link>
                    </Box>
                    <Box sx={{
                        my: 1
                    }}>
                        <Link href="/#/contacto" variant="body2" color="textSecondary" underline="hover">
                            Contacto
                            <br/>
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={4} md={2}>
                    <Typography variant="h6" fontWeight="bold" color="textSecondary">
                        Contacto
                    </Typography>
                    <Box sx={{
                        my: 1
                    }}>
                        <Link
                            href="https://www.instagram.com/trackthetrucker/"
                            variant="body2"
                            color="textSecondary"
                            underline="hover"
                            target="_blank"
                            rel="noreferrer">
                            Instagram
                            <br/>
                        </Link>
                    </Box>
                    <Box sx={{
                        my: 1
                    }}>
                        <Link
                            href="https://www.linkedin.com/company/trackthetrucker/"
                            variant="body2"
                            target="_blank"
                            rel="noreferrer"
                            color="textSecondary"
                            underline="hover">
                            Linkedin
                            <br/>
                        </Link>
                    </Box>
                    <Box sx={{
                        my: 1
                    }}>
                        <Link
                            href="mailto:trackthetrucker@gmail.com"
                            variant="body2"
                            target="_blank"
                            rel="noreferrer"
                            color="textSecondary"
                            underline="hover">
                            Mail
                            <br/>
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={4} md={2}>
                    <Typography variant="h6" fontWeight="bold" color="textSecondary">
                        Créditos
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        underline="hover"
                        sx={{
                        my: 1
                    }}>
                        <Link
                            href="https://icons8.com/icon/kfnjM4KPaLxk/instagram"
                            target="_blank"
                            rel="noreferrer"
                            color="textSecondary">
                            Instagram</Link> icon by <Link target="_blank" rel="noreferrer" href="https://icons8.com" color="textSecondary">Icons8</Link>
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        underline="hover"
                        sx={{
                        my: 1
                    }}>
                        <Link
                            href="https://icons8.com/icon/CqSHNNO0qG0w/linkedin---in-logo-used-for-professional-networking%2C"
                            target="_blank"
                            rel="noreferrer"
                            color="textSecondary">
                            Linkedin</Link> icon by <Link target="_blank" rel="noreferrer" href="https://icons8.com" color="textSecondary">Icons8</Link>
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        underline="hover"
                        sx={{
                        my: 1
                    }}>
                        <Link
                            href="https://icons8.com/icon/YpTJTJYKapL1/google-an-american-multinational-technology-company-that-specializes-in-internet-related-services-and-products"
                            target="_blank"
                            rel="noreferrer"
                            color="textSecondary">
                            Gmail</Link> icon by <Link href="https://icons8.com" target="_blank" rel="noreferrer" color="textSecondary">Icons8</Link>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{
                        my: 3
                    }}>
                        <Chip label="Copyright © 2023"/>
                    </Divider>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="textSecondary"
                        align="center">
                        Made with love and lots of coffee by Eureka Code 🖤
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Footer;
