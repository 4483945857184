import './Pages.css';
import LinkedinIcon from '../Media/icons8-linkedin.png';
import EmailIcon from '../Media/icons8-email.png';
import React, {useState, useEffect} from 'react';
import Post from '../Media/post.jpg';
import ProfilePicture from '../Media/logo-ig.png';
import InstagramIcon from '../Media/icons8-instagram.png';
import {
    Grid,
    Box,
    Typography,
    Card,
    CardContent,
    Fade,
    CardMedia,
    CardActionArea
} from '@mui/material';

const Contacto = () => {

    const [checked,
        setChecked] = useState(false);

    useEffect(() => {
        setChecked(true);
    }, []);

    return (
        <Grid container alignItems="stretch">
            <Fade
                style={{
                transitionDelay: checked
                    ? '500ms'
                    : '0ms'
            }}
                in={checked}
                {...(checked ? { timeout: 500 } : {})}>
                <Grid item xs={12} md={9} lg={6}>
                    <Box
                        display="flex"
                        minHeight="100vh"
                        flexDirection="column"
                        sx={{
                        px: 2
                    }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    fontWeight="bold"
                                    className="titleVariant2"
                                    sx={{
                                    mb: 1,
                                    ml: 1,
                                    '@media (min-width: 1200px)': {
                                        mt: 10
                                    }
                                }}>
                                    Contacto
                                </Typography>
                            </Grid>
                            <Grid item xs="12" sm="6">
                                <Card
                                    sx={{
                                    my: 1,
                                    mx: 1
                                }}>
                                    <CardActionArea href='https://www.instagram.com/trackthetrucker/' target="_blank" rel="noreferrer">
                                        <CardContent
                                            sx={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                            <img alt="" src={InstagramIcon} className="iconSocial"/>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                ml: 1
                                            }}>@trackthetrucker</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Card
                                    sx={{
                                    my: 1,
                                    mx: 1
                                }}>
                                    <CardActionArea href='https://www.linkedin.com/company/trackthetrucker/' target="_blank" rel="noreferrer">
                                        <CardContent
                                            sx={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                            <img alt="" src={LinkedinIcon} className="iconSocial"/>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                ml: 1
                                            }}>Track The Trucker</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Card
                                    sx={{
                                    my: 1,
                                    mx: 1
                                }}>
                                    <CardActionArea href='mailto:trackthetrucker@gmail.com' target="_blank" rel="noreferrer">
                                        <CardContent
                                            sx={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                            <img alt="" src={EmailIcon} className="iconSocial"/>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                ml: 1
                                            }}>trackthetrucker@gmail.com</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Card
                                    sx={{
                                    my: 1,
                                    mx: 1
                                }}>
                                    <CardContent>
                                        <Typography>
                                            ¡Visítanos en Feria de Software! ✨ 💻
                                        </Typography>
                                    </CardContent>
                                    <iframe
                                        title="Video Explicativo"
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3327.367385419601!2d-70.6213731!3d-33.4918175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662d006fa56ee51%3A0x81dacfbe20db68cf!2sUniversidad%20T%C3%A9cnica%20Federico%20Santa%20Mar%C3%ADa!5e0!3m2!1ses-419!2scl!4v1692290564639!5m2!1ses-419!2scl"
                                        width="100%"
                                        height="300"
                                        allowfullscreen=""
                                        loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"
                                        style={{
                                        border: 'none'
                                    }}></iframe>
                                    <CardContent>
                                        <Typography variant="caption">
                                            📅 10 de Noviembre
                                            <br/>
                                            📍 Universidad Técnica Federico Santa María Campus San Joaquín
                                            <br/>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs="12" sm="6">
                                <Card
                                    sx={{
                                    display: 'flex',
                                    my: 1,
                                    mx: 1
                                }}>
                                    <CardActionArea href='https://www.instagram.com/p/CtP8FYKsL8P/' target="_blank" rel="noreferrer">
                                        <Box
                                            sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'flex-end',
                                            ml: 2,
                                            my: 1,
                                        }}>
                                            <img src={ProfilePicture} alt="logo" className="logoInstagram"/>
                                            <Typography gutterBottom component="div" color="text.secondary">
                                                @trackthetrucker
                                            </Typography>
                                        </Box>
                                        <CardMedia component="img" image={Post} alt="track the trucker post"/>
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                fontSize: 14
                                            }}
                                                color="text.secondary"
                                                component="div">
                                                08/06/23
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary">
                                                ¿Qué es Track The Trucker? Con este breve resumen te presentamos a grandes
                                                rasgos lo que hacemos 📸 🚚
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Fade>
            <Fade in={checked} {...(checked ? { timeout: 600 } : {})}>
                <Grid item lg={6}>
                    <Box
                        className='boxImage4'
                        display="flex"
                        minHeight="100vh"
                        height="100%"
                        flexDirection="column"></Box>
                </Grid>
            </Fade>
        </Grid>
    );
};

export default Contacto;
