import React from 'react';
import './Navbar.css';
import {NavLink} from 'react-router-dom';
import {Box, Typography} from '@mui/material';

const NavbarRight = () => {
    return (
        <Box
            sx={{
            position: 'absolute',
            textAlign: 'right',
            right: 15,
            zIndex: 1000 
        }}>
            <Box sx={{
                my: 3
            }}>
                <NavLink
                    to="/"
                    className={(navData) => navData.isActive
                    ? 'activeLink'
                    : 'linkNavbar'}>
                    <Typography variant="button">Inicio
                        <br/></Typography>
                </NavLink>
            </Box>
            <Box sx={{
                my: 3
            }}>
                <NavLink
                    to="/que-es-Track-The-Trucker"
                    exact
                    className={(navData) => navData.isActive
                    ? 'activeLink'
                    : 'linkNavbar'}>
                    <Typography variant="button">Qué es Track The Trucker
                        <br/></Typography>
                </NavLink>
            </Box>
            <Box sx={{
                my: 3
            }}>
                <NavLink
                    to="/quienes-somos"
                    className={(navData) => navData.isActive
                    ? 'activeLink'
                    : 'linkNavbar'}>
                    <Typography variant="button">Quiénes Somos
                        <br/></Typography>
                </NavLink>
            </Box>
            <Box sx={{
                my: 3
            }}>
                <NavLink
                    to="/contacto"
                    className={(navData) => navData.isActive
                    ? 'activeLink'
                    : 'linkNavbar'}>
                    <Typography variant="button">Contacto</Typography>
                </NavLink>
            </Box>
        </Box>
    );
};

export default NavbarRight;
