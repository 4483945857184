import React from 'react';
import './Navbar.css';
import {NavLink} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Toolbar,
    Box,
    Typography,
    List,
    ListItem,
    ListItemButton,
    Drawer,
    IconButton
} from '@mui/material';
import logo from '../Media/logo-ig.png'

const NavbarSmall = () => {
    const [state,
        setState] = React.useState({top: false});

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({
            ...state,
            [anchor]: open
        });
    };

    const list = (anchor) => (
        <Box
            sx={{
            width: anchor === 'top' || anchor === 'bottom'
                ? 'auto'
                : 250
        }}
            role="presentation">
            <List>
                <ListItem disablePadding>
                    <ListItemButton to="/" onClick={toggleDrawer(anchor, false)}>
                        <NavLink
                            to="/"
                            className={(navData) => navData.isActive
                            ? 'activeLinkBlack'
                            : 'linkNavbarBlack'}>
                            <Typography
                                variant="button"
                                sx={{
                                mr: 2
                            }}>Inicio</Typography>
                        </NavLink>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        to="/que-es-Track-The-Trucker"
                        onClick={toggleDrawer(anchor, false)}>
                        <NavLink
                            to="/que-es-Track-The-Trucker"
                            exact
                            className={(navData) => navData.isActive
                            ? 'activeLinkBlack'
                            : 'linkNavbarBlack'}>
                            <Typography
                                variant="button"
                                sx={{
                                mr: 2
                            }}>Qué es Track The Trucker</Typography>
                        </NavLink>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton to="/quienes-somos" onClick={toggleDrawer(anchor, false)}>
                        <NavLink
                            to="/quienes-somos"
                            className={(navData) => navData.isActive
                            ? 'activeLinkBlack'
                            : 'linkNavbarBlack'}>
                            <Typography
                                variant="button"
                                sx={{
                                mr: 2
                            }}>Quiénes Somos</Typography>
                        </NavLink>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton to="/contacto" onClick={toggleDrawer(anchor, false)}>
                        <NavLink
                            to="/contacto"
                            className={(navData) => navData.isActive
                            ? 'activeLinkBlack'
                            : 'linkNavbarBlack'}>
                            <Typography variant="button">Contacto</Typography>
                        </NavLink>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <AppBar
            position="static"
            color="transparent"
            sx={{
            boxShadow: 0
        }}>
            <Toolbar
                sx={{
                alignItems: 'flex-start',
                mt: 1
            }}>
                <Box sx={{
                    flexGrow: 1
                }}>
                    <a href="/">
                        <img alt="logo" src={logo} className="logoNavbar"/>
                    </a>
                </Box>
                <div>
                    {['top'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <IconButton color="primary" size="large" onClick={toggleDrawer(anchor, true)}><MenuIcon fontSize="inherit"/></IconButton>
                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}>
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default NavbarSmall;