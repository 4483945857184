import {Box} from '@mui/material';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './Components/Navbar';
import NavbarSmall from './Components/NavbarSmall';
import Inicio from './Pages/Inicio';
import TrackTheTrucker from './Pages/TrackTheTrucker';
import QuienesSomos from './Pages/QuienesSomos';
import Contacto from './Pages/Contacto';
import Footer from './Components/Footer';
import {useState, useEffect} from 'react';
import NotFound from './Pages/NotFound';
import NavbarRight from './Components/NavbarRight';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-QKTRK9CDFB', {gaOptions: {cookieFlags: 'SameSite=None;Secure'}, gtagOptions: {cookie_flags: 'SameSite=None;Secure'}});

function App() {

    const [isScreenSmall,
        setIsSmallScreen] = useState(window.innerWidth <= 1200);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Router>
            <Box
                display="flex"
                flexDirection="column"
                minHeight="100vh"
                sx={{
                backgroundColor: "#F5F7FB"
            }}>

                {isScreenSmall
                    ? <NavbarSmall/>
                    : <>< Navbar /> <NavbarRight/> </>}
                <Box
                    sx={{
                    '@media (max-width: 1200px)': {
                        mb: 4
                    }
                }}>
                    <Routes>
                        <Route exact path="/" element={< Inicio />}></Route>
                        <Route exact path="/que-es-track-the-trucker" element={< TrackTheTrucker />}></Route>
                        <Route exact path="/quienes-somos" element={< QuienesSomos />}></Route>
                        <Route exact path="/contacto" element={< Contacto />}></Route>
                        <Route exact path="*" element={< NotFound />}></Route>
                    </Routes>
                </Box>
                <Footer/>
            </Box>
        </Router>
    );
}

export default App;
