import './Navbar.css';
import React from 'react';
import {Toolbar, Box} from '@mui/material';
import logo from '../Media/logo-ig.png'

const Navbar = () => {
    return (
        <Box
            color="transparent"
            sx={{
            position: 'absolute',
            boxShadow: 0
        }}>
            <Toolbar
                sx={{
                alignItems: 'flex-start',
                mt: 1
            }}>
                <Box>
                    <a href="/">
                        <img alt="logo" src={logo} className="logoNavbar"/>
                    </a>
                </Box>
            </Toolbar>
        </Box>
    );
};

export default Navbar;
