import React from 'react';
import {VictoryPie, VictoryAnimation, VictoryLabel} from 'victory';

class Progress extends React.Component {
    constructor() {
        super();
        this.state = {
            percent: 0,
            data: this.getData(0)
        };
    }

    componentDidMount() {
        this.animateOnce(); 
    }

    componentWillUnmount() {
        window.clearInterval(this.setStateInterval);
    }

    animateOnce = () => {
        let percent = 0;
        this.setStateInterval = window.setInterval(() => {
            percent += 59 ;
            percent = percent > 59
                ? 0
                : percent;
            this.setState({
                percent,
                data: this.getData(percent)
            });

            if (percent === 59) {
                clearInterval(this.setStateInterval);
            }
        }, 2000);
    };

    getData(percent) {
        return [
            {
                x: 1,
                y: percent
            }, {
                x: 2,
                y: 100 - percent
            }
        ];
    }

    render() {

        return (

            <div>

                <svg viewBox="0 0 400 400" width="100%" height="100%">
                    <defs>
                        <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="#0ab7fc"/>
                            <stop offset="100%" stopColor="#254cfb"/>
                        </linearGradient>
                    </defs>
                    <VictoryPie
                        standalone={false}
                        animate={{
                        duration: 700
                    }}
                        width={400}
                        height={400}
                        data={this.state.data}
                        innerRadius={120}
                        cornerRadius={25}
                        labels={() => null}
                        style={{
                        data: {
                            fill: ({datum}) => {
                                const color = datum.y > 30
                                    ? 'url(#gradient1)'
                                    : 'url(#gradient1)';
                                return datum.x === 1
                                    ? color
                                    : 'transparent';
                            }
                        }
                    }}/>
                    <VictoryAnimation duration={1000} data={this.state}>
                        {(newProps) => {
                            return (<VictoryLabel
                                textAnchor="middle"
                                verticalAnchor="middle"
                                x={200}
                                y={200}
                                text={`${Math.round(newProps.percent)}%`}
                                style={{
                                fontSize: 100
                            }}/>);
                        }}
                    </VictoryAnimation>
                </svg>
            </div>
        );
    }
}

export default Progress;
