import './Pages.css';
import React, {useState, useEffect} from 'react';
import {
    Grid,
    Box,
    Typography,
    Fade
} from '@mui/material';

const NotFound = () => {

    const [checked,
        setChecked] = useState(false);

    useEffect(() => {
        setChecked(true);
    }, []);

    return (
        <Grid container alignItems="stretch">
            <Fade
                style={{
                transitionDelay: checked
                    ? '500ms'
                    : '0ms'
            }}
                in={checked}
                {...(checked ? { timeout: 500 } : {})}>
                <Grid item xs={12} md={9} lg={6}>
                    <Box
                        display="flex"
                        minHeight="100vh"
                        flexDirection="column"
                        alignContent="center"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                        px: 2,
                    }}>
                        <Typography
                            fontWeight="bold"
                            className="animatedVariant"
                            sx={{
                            fontSize: '13rem',
                            mb: -5
                        }}>
                            404
                        </Typography>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            className="animatedVariant"
                            sx={{
                            fontSize: '2rem'
                        }}>
                            PAGE NOT FOUND
                        </Typography>
                    </Box>
                </Grid>
            </Fade>
            <Fade in={checked} {...(checked ? { timeout: 600 } : {})}>
                <Grid item lg={6}>
                    <Box
                        className='boxImage5'
                        display="flex"
                        minHeight="100vh"
                        height="100%"
                        flexDirection="column"></Box>
                </Grid>
            </Fade>
        </Grid>
    );
};

export default NotFound;
