import './Pages.css';
import React, {useState, useEffect} from 'react';
import {
    Grid,
    Box,
    Typography,
    Card,
    CardContent,
    Fade,
    CardActions,
    CardMedia,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import cover1 from '../Media/ph-cover.jpg';
import profile1 from '../Media/ph.jpg';
import cover2 from '../Media/pv-cover.jpg';
import profile2 from '../Media/pv.jpg';
import cover3 from '../Media/am-cover.jpg';
import profile3 from '../Media/am.jpg';
import cover4 from '../Media/fc-cover.jpg';
import profile4 from '../Media/fc.jpg';
import cover5 from '../Media/er-cover.jpg';
import profile5 from '../Media/er.jpg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logoEureka from '../Media/logo-eureka.png';

const QuienesSomos = () => {

    const [checked,
        setChecked] = useState(false);

    useEffect(() => {
        setChecked(true);
    }, []);

    const [expanded,
        setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded
            ? panel
            : false);
    };

    return (
        <Grid container alignItems="stretch">
            <Fade
                style={{
                transitionDelay: checked
                    ? '500ms'
                    : '0ms'
            }}
                in={checked}
                {...(checked ? { timeout: 500 } : {})}>
                <Grid item xs={12} md={10} lg={7}>
                    <Box
                        display="flex"
                        minHeight="100vh"
                        flexDirection="column"
                        sx={{
                        px: 2
                    }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    fontWeight="bold"
                                    className="titleVariant2"
                                    sx={{
                                    mb: 1,
                                    ml: 1,
                                    '@media (min-width: 1200px)': {
                                        mt: 10
                                    }
                                }}>
                                    Quiénes
                                    <br/>
                                    Somos?
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                xl={3}
                                sx={{
                                py: 1,
                                px: 1
                            }}>
                                <Tooltip title="Eureka Code logo" arrow>
                                    <Box sx={{display: 'flex', justifyContent: 'center', mb: 2}}>
                                        <img src={logoEureka} width="100" alt="Eureka Code logo" />
                                    </Box>
                                </Tooltip>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={< ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <Typography>
                                            🚀 Misión</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body2">
                                            Elevar los estándares de identificación de conductores con precisión, rapidez y
                                            confiabilidad para que cada viaje sea más seguro y eficiente.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion >
                                <Accordion
                                    expanded={expanded === 'panel2'}
                                    onChange={handleChange('panel2')}
                                    sx={{
                                    my: 1
                                }}>
                                    <AccordionSummary
                                        expandIcon={< ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header">
                                        <Typography>🎉 Visión</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body2">
                                            Transformar el panorama vial mundial a través de la identificación precisa y
                                            segura de cada conductor, haciendo de las carreteras un lugar más seguro para
                                            todos.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === 'panel3'}
                                    onChange={handleChange('panel3')}
                                    sx={{
                                    my: 1
                                }}>
                                    <AccordionSummary
                                        expandIcon={< ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header">
                                        <Typography>💫 Valores</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body2">
                                            <b>Integridad: </b>
                                            Actuamos con honestidad y transparencia en todas nuestras acciones.
                                            <br/>
                                            <br/>
                                            <b>Responsabilidad: </b>
                                            Reconocemos la importancia de nuestra labor en la seguridad vial y asumimos
                                            nuestra tarea con seriedad y compromiso.
                                            <br/>
                                            <br/>
                                            <b>Confidencialidad: </b>
                                            Protegemos la información y privacidad de nuestros usuarios.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12} md={8} xl={9}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        xl={4}
                                        sx={{
                                        py: 1,
                                        px: 1
                                    }}>
                                        <Card
                                            sx={{
                                            height: '100%'
                                        }}>
                                            <Box
                                                sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}>
                                                <Avatar
                                                    alt="Pedro Herrera"
                                                    src={profile1}
                                                    sx={{
                                                    width: 85,
                                                    height: 85,
                                                    position: 'absolute',
                                                    mt: 3,
                                                    mr: 1
                                                }}/>
                                            </Box>
                                            <CardMedia component="img" height="80" image={cover1}/>
                                            <CardContent>
                                                <Typography variant="caption" color="text.secondary">
                                                    Product Owner
                                                </Typography>
                                                <Typography variant="h6" component="div">
                                                    Pedro Herrera
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <a
                                                    href="https://www.linkedin.com/in/pedroherreraso/"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <LinkedInIcon className="iconSmall"/>
                                                </a>
                                                <a
                                                    href="mailto:pedro.herreraso@sansano.usm.cl"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <EmailIcon className="iconSmall"/>
                                                </a>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        xl={4}
                                        sx={{
                                        py: 1,
                                        px: 1
                                    }}>
                                        <Card
                                            sx={{
                                            height: '100%'
                                        }}>
                                            <Box
                                                sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}>
                                                <Avatar
                                                    alt="Paulina Vega"
                                                    src={profile2}
                                                    sx={{
                                                    width: 85,
                                                    height: 85,
                                                    position: 'absolute',
                                                    mt: 3,
                                                    mr: 1
                                                }}/>
                                            </Box>
                                            <CardMedia component="img" height="80" image={cover2}/>
                                            <CardContent>
                                                <Typography variant="caption" color="text.secondary">
                                                    Scrum Master
                                                </Typography>
                                                <Typography variant="h6" component="div">
                                                    Paulina Vega
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <a
                                                    href="https://www.linkedin.com/in/paulina-vega-rivera/"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <LinkedInIcon className="iconSmall"/>
                                                </a>
                                                <a href="mailto:paulina.vegar@sansano.usm.cl" target="_blank" rel="noreferrer">
                                                    <EmailIcon className="iconSmall"/>
                                                </a>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        xl={4}
                                        sx={{
                                        py: 1,
                                        px: 1
                                    }}>
                                        <Card
                                            sx={{
                                            height: '100%'
                                        }}>
                                            <Box
                                                sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}>
                                                <Avatar
                                                    alt="Angelo Miranda"
                                                    src={profile3}
                                                    sx={{
                                                    width: 85,
                                                    height: 85,
                                                    position: 'absolute',
                                                    mt: 3,
                                                    mr: 1
                                                }}/>
                                            </Box>
                                            <CardMedia component="img" height="80" image={cover3}/>
                                            <CardContent>
                                                <Typography variant="caption" color="text.secondary">
                                                    Líder de Marketing
                                                </Typography>
                                                <Typography variant="h6" component="div">
                                                    Angelo Miranda
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <a
                                                    href="https://www.linkedin.com/in/angelo-miranda-espinoza/"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <LinkedInIcon className="iconSmall"/>
                                                </a>
                                                <a href="mailto:angelo.miranda@sansano.usm.cl" target="_blank" rel="noreferrer">
                                                    <EmailIcon className="iconSmall"/>
                                                </a>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        xl={4}
                                        sx={{
                                        py: 1,
                                        px: 1
                                    }}>
                                        <Card
                                            sx={{
                                            height: '100%'
                                        }}>
                                            <Box
                                                sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}>
                                                <Avatar
                                                    alt="Franco Cabezas"
                                                    src={profile4}
                                                    sx={{
                                                    width: 85,
                                                    height: 85,
                                                    position: 'absolute',
                                                    mt: 3,
                                                    mr: 1
                                                }}/>
                                            </Box>
                                            <CardMedia component="img" height="80" image={cover4}/>
                                            <CardContent>
                                                <Typography variant="caption" color="text.secondary">
                                                    Encargado de Tecnologías
                                                </Typography>
                                                <Typography variant="h6" component="div">
                                                    Franco Cabezas
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <a
                                                    href="https://www.linkedin.com/in/franco-cabezas-poblete/"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <LinkedInIcon className="iconSmall"/>
                                                </a>
                                                <a
                                                    href="mailto:franco.cabezasp@sansano.usm.cl"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <EmailIcon className="iconSmall"/>
                                                </a>

                                            </CardActions>
                                        </Card>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        xl={4}
                                        sx={{
                                        py: 1,
                                        px: 1
                                    }}>
                                        <Card
                                            sx={{
                                            height: '100%'
                                        }}>
                                            <Box
                                                sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}>
                                                <Avatar
                                                    alt="Etienne Rojas"
                                                    src={profile5}
                                                    sx={{
                                                    width: 85,
                                                    height: 85,
                                                    position: 'absolute',
                                                    mt: 3,
                                                    mr: 1
                                                }}/>
                                            </Box>
                                            <CardMedia component="img" height="80" image={cover5}/>
                                            <CardContent>
                                                <Typography variant="caption" color="text.secondary">
                                                    Encargado de Pruebas
                                                </Typography>
                                                <Typography variant="h6" component="div">
                                                    Etienne Rojas
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <a
                                                    href="https://www.linkedin.com/in/etienne-rojas-calderon/"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <LinkedInIcon className="iconSmall"/>
                                                </a>
                                                <a href="mailto:etienne.rojas@sansano.usm.cl" target="_blank" rel="noreferrer">
                                                    <EmailIcon className="iconSmall"/>
                                                </a>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        xl={4}
                                        sx={{
                                        py: 1,
                                        px: 1
                                    }}>
                                        <Card
                                            sx={{
                                            height: '100%'
                                        }}>
                                            <CardContent>
                                                <Typography className="animatedVariant" fontWeight={500}>
                                                    ⭐ Eureka Code
                                                </Typography>
                                            </CardContent>
                                            <CardContent>
                                                <Typography variant="body2">
                                                    Eureka Code está formado de apasionados universitarios que decidieron combinar
                                                    sus talentos para crear el producto Track The Trucker! 🚛 💞
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Fade>
            <Fade in={checked} {...(checked ? { timeout: 600 } : {})}>
                <Grid item lg={5}>
                    <Box
                        className="boxImage3"
                        display="flex"
                        minHeight="100vh"
                        height="100%"
                        flexDirection="column"></Box>
                </Grid>
            </Fade>
        </Grid>
    );
};

export default QuienesSomos;
