import './Pages.css';
import videoOne from '../Media/videoCOMPRESS.mp4';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import React, {useState, useEffect} from 'react';
import ReactPlayer from 'react-player';
import {
    Grid,
    Box,
    Typography,
    Card,
    CardContent,
    Fade
} from '@mui/material';

const Inicio = () => {

    const [checked,
        setChecked] = useState(false);

    useEffect(() => {
        setChecked(true);
    }, []);

    return (
        <Grid container alignItems="stretch">
            <Fade
                style={{
                transitionDelay: checked
                    ? '500ms'
                    : '0ms'
            }}
                in={checked}
                {...(checked ? { timeout: 500 } : {})}>
                <Grid item xs={12} md={9} lg={6}>
                    <Box
                        display="flex"
                        minHeight="100vh"
                        flexDirection="column"
                        sx={{
                        px: 2
                    }}>
                        <Typography
                            fontWeight="bold"
                            className="titleVariant"
                            sx={{
                            mb: 1,
                            ml: 1,
                            '@media (min-width: 1200px)': {
                                mt: 10
                            }
                        }}>
                            Track
                            <br/>
                            The
                            <br/>
                            Trucker
                        </Typography>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            className="animatedVariant"
                            sx={{
                            mx: 1,
                            my: 1
                        }}>
                            Identidad al volante.
                        </Typography>
                        <Card
                            className="cardInicio"
                            sx={{
                            display: "flex",
                            height: 'auto',
                            mx: 1,
                            my: 1
                        }}>
                            <Grid container spacing={2} direction="row" justifyContent="space-between">
                                <Grid item sm="4" md="7">
                                    <Box>
                                        <CardContent>
                                            <Typography
                                                variant='body1'
                                                sx={{
                                                mb: 1
                                            }}>
                                                Video explicativo
                                            </Typography>
                                            <Typography
                                                variant='caption'
                                                sx={{
                                                mb: 1
                                            }}>
                                                Track The Trucker permite la identificación de conductores mediante el uso de inteligencia artificial.
                                            </Typography>
                                            <Box
                                                sx={{
                                                mt: 1
                                            }}>
                                                <a href="https://www.linkedin.com/company/trackthetrucker/" target="_blank" rel="noreferrer">
                                                    <LinkedInIcon
                                                        className="iconSmall"
                                                        sx={{
                                                        mr: 1
                                                    }}/>
                                                </a>
                                                <a href="https://www.instagram.com/trackthetrucker/" target="_blank" rel="noreferrer">
                                                    <InstagramIcon
                                                        className="iconSmall"
                                                        sx={{
                                                        mr: 1
                                                    }}/>
                                                </a>
                                                <a href="mailto:trackthetrucker@gmail.com" target="_blank" rel="noreferrer">
                                                    <EmailIcon className="iconSmall"/>
                                                </a>
                                            </Box>
                                        </CardContent>
                                    </Box>
                                </Grid>
                                <Grid item sm="8" md="5">
                                    <Box
                                        sx={{
                                        mb: -1,
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <ReactPlayer
                                            url={videoOne}
                                            playing
                                            controls
                                            muted
                                            loop
                                            width="100%"
                                            height="100%"/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card
                            sx={{
                            display: "flex",
                            height: 'auto',
                            mx: 1,
                            my: 1
                        }}>
                            <Box>
                                <CardContent>
                                    <Typography variant='body1'>
                                        ¡No olvides visitarnos en la Feria de Software, organizada por el Departamento
                                        de Informática de la UTFSM! ✨ 
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Box>
                </Grid>
            </Fade>
            <Fade in={checked} {...(checked ? { timeout: 600 } : {})}>
                <Grid item lg={6}>
                    <Box
                        className='boxImage1'
                        display="flex"
                        minHeight="100vh"
                        height="100%"
                        flexDirection="column"></Box>
                </Grid>
            </Fade>
        </Grid>
    );
};

export default Inicio;
